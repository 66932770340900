.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  height: 90vh;
  scroll-snap-align: start;
  flex-flow: column;
  text-transform: uppercase;
  min-height: 100%;
}