html {
    height: 100%;
    scroll-behavior: smooth;
    user-select: none;
}
body {
    font-family: 'Montserrat', sans-serif;
    background-color: antiquewhite;
    margin: 0;
    font-weight: 400 !important;
    height: 100%;
}

a {
    all: unset;
}
