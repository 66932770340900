.imgcontainer {
  width: 300px;
  height: 300px;

  @media (max-width: 62rem) {
    width: 250px;
    height: 250px;
  }


  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
  }
}

.paper {
  width: 80%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  height: 500px;
  margin-bottom: 50px;

  @media (max-width: 62rem) {
    flex-flow: column;
    height: 400px;
  }
}


.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  scroll-snap-align: start;
  flex-flow: column nowrap;
  min-height: 100%;
}

.text {
  overflow: hidden;
  white-space: nowrap;
  width: auto;

  height: 80px;

  @media (max-width: 62rem) {
    font-size: 14px;
  }

  &.animation {
    border-right: .15em solid orange;
    width: 0;
    animation: typing 2s steps(40, end) forwards,
    blink .8s infinite;
  }
}

@keyframes typing {
  from {
    width: 0
  }
  to {
    width: 100%
  }
}

/* The typewriter cursor effect */
@keyframes blink {
  from {
    border-color: transparent
  }
  to {
    border-color: orange;
  }
}

.textblock {
  width: 300px;
  justify-content: center;
  display: flex;
  align-items: center;

  @media (max-width: 62rem) {
    width: 255px;
  }

  &.english {
    width: 210px;

    @media (max-width: 62rem) {
      width: 180px;
    }
  }
}