.button {
  margin-right: 10px;
  margin-left: 10px;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 400 !important;


  &:hover {
    background-color: rgb(82, 10, 13, 0.5) !important;
  }
}

.name {
  font-family: 'Montserrat', sans-serif !important;
  font-variant: small-caps;
  font-weight: 400 !important;
}