.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  height: 90vh;
  min-height: 100%;
  scroll-snap-align: start;
    flex-flow: column;
}

.bubblecontainer {
  display: flex;
  flex-flow: row nowrap;

  @media (max-width: 48rem) {
    flex-flow: column;
  }
}

.bubbles {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: #D11922;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    max-width: 100%;
    max-height: 100%;
    color: white;
  }

  &:hover {
    cursor: pointer;
    background-color: #86211E;
  }
}